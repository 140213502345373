<template>
  <div class="f1">直接与我们联系</div>
  <div class="f2">
    想要了解更多关于我们的服务？
    请直接打我们电话联系﹐我们很乐意听取您的意见！<br />
    接待时间 9：00～18:00 （周末节假日除外）
  </div>
  <div class="map" id="map"></div>
</template>
<script>
export default {
  mounted() {
    let BMapGL = window.BMapGL;
    let map = new BMapGL.Map("map"); // 创建地图实例
    let point = new BMapGL.Point(114.066028, 22.610228); // 创建点坐标
    map.centerAndZoom(point, 17);
    map.enableScrollWheelZoom(true);
    let marker = new BMapGL.Marker(point);
    map.addOverlay(marker);
    let opts = {
      width: 250, // 信息窗口宽度
      height: 70, // 信息窗口高度
      title: "常达智能物联（深圳）有限公司", // 信息窗口标题
    };
    let infoWindow = new BMapGL.InfoWindow(
      "地址：深圳市龙岗区雅宝路1号星河WORLD A栋大厦13层  A1303B",
      opts
    ); // 创建信息窗口对象
    map.openInfoWindow(infoWindow, map.getCenter());
    marker.addEventListener("click", function () {
      map.openInfoWindow(infoWindow, point); //开启信息窗口
    });
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .map {
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    @if ($searchWidth<1100) {
      height: 14rem;
      margin: 0 0.8rem;
      font-size: 0.8rem;
    } @else {
      max-width: 13.2rem;
      height: 4.5rem;
      margin: auto;
      font-size: 0.16rem;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
