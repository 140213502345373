<template>
  <div class="rangeWarp">
    <Title
      :title="'全球业务 + 制造'"
      :sub-title="'无论身在何处，常达均能为你提供优质的服务'"
      class="titles"
    ></Title>
    <div class="nums-warp pc">
      <div class="num-left">
        <template v-for="(item, index) in left" :key="index">
          <aboutNumComponent
            :nums="item.nums"
            :unit="item.unit"
            :mes="item.mes"
          ></aboutNumComponent>
        </template>
      </div>
      <div class="num-right">
        <template v-for="(item, index) in right" :key="index">
          <aboutNumComponent
            :nums="item.nums"
            :unit="item.unit"
            :mes="item.mes"
          ></aboutNumComponent>
        </template>
      </div>
    </div>
    <!--    <img class="moblie imgs" :src="require('@/assets/about/ranges.png')"/>-->
    <div class="nums-warp-m moblie">
      <div class="num-left-m">
        <template v-for="(item, index) in left" :key="index">
          <aboutNumComponent
            :nums="item.nums"
            :unit="item.unit"
            :mes="item.mes"
          ></aboutNumComponent>
        </template>
      </div>
      <div class="num-right-m">
        <template v-for="(item, index) in right" :key="index">
          <aboutNumComponent
            :nums="item.nums"
            :unit="item.unit"
            :mes="item.mes"
          ></aboutNumComponent>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import aboutNumComponent from "@components/about/about-num-component.vue";
import Title from "@/views/plans/components/Title.vue";
export default {
  data() {
    return {
      left: [
        {
          nums: 8,
          unit: "个",
          mes: "国家设立工厂",
        },
        {
          nums: 40,
          unit: "+",
          mes: "成立办事处",
        },
        {
          nums: 400,
          unit: "部",
          mes: "生产机器",
        },
      ],
      right: [
        {
          nums: 16,
          unit: "项",
          mes: "实用型专利",
        },
        {
          nums: 13,
          unit: "项",
          mes: "软件著作权",
        },
      ],
    };
  },
  components: {
    aboutNumComponent,
    Title,
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .rangeWarp {
    margin-top: 0.3rem;
    height: 6.9rem;
    @if ($searchWidth<1100) {
      background: url("~@/assets/about/about.png");
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 600px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    } @else {
      background: url("~@/assets/about/ranges.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
  ::v-deep(.titles) {
    .plansTitle {
      padding-top: 50px;
      @if ($searchWidth<1100) {
        padding-top: 20px;
      }
    }
    .plansSubTitle {
      padding-bottom: 0;
      margin-top: -30px;
      @if ($searchWidth<1100) {
        margin-top: -10px;
        font-size: 0.8rem;
      }
    }
  }
  .nums-warp-m {
    margin-left: 18px;
    margin-bottom: 66px;
    align-self: flex-start;
    .num-left-m {
      display: flex;
      margin-bottom: 30px;
    }
    .num-right-m {
      display: flex;
    }
  }
  .nums-warp {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    @if ($searchWidth<1100) {
      width: 70%;
    } @else {
      width: 12rem;
    }
    .num-right {
      align-self: flex-end;
      @if ($searchWidth>1100) {
        transform: translateY(1rem);
      }
      ::v-deep(:last-child) {
        margin-bottom: 0;
      }
    }
  }
  .imgs {
    width: 100%;
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
