<template>
  <top :title="'关于我们'" />
  <!--  <div id="全球业务"></div>-->
  <range />
  <info id="公司简介" />
  <produce id="生产实力" />
  <div id="联系我们"></div>
  <my-map />
  <div class="footer-br"></div>
  <common-footer></common-footer>
</template>
<script>
import top from "@components/top.vue";
import range from "./range";
import info from "./info";
import produce from "./produce";
import myMap from "./map";
import CommonFooter from "@components/footer.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    top,
    range,
    info,
    produce,
    myMap,
    CommonFooter,
  },
  methods: {
    ...mapMutations([
      "scrollToSection",
      "setHeaderColorBlack",
      "setHeaderColorWhite",
      "setheaderTopBtnWhite",
    ]),
  },

  mounted() {
    this.scrollToSection(this.$route.hash);
    this.setheaderTopBtnWhite(false);
  },
  unmounted() {
    this.setheaderTopBtnWhite(false);
  },
};
</script>
<style lang="scss" scoped></style>
