<template>
  <div class="top">
    <img
      class="pc"
      :src="require('@/assets/topBackImg/' + titleDate.pcBackImg)"
    />
    <img
      class="moblie"
      :src="require('@/assets/topBackImg/' + titleDate.mobieBackImg)"
    />
    <div class="text" :class="{ isBlack: color == 'black' }">
      <div class="t1 wow fadeInRight">{{ titles }}</div>
      <div class="t2 wow fadeInRight" data-wow-delay="0.3s">
        {{ titleDate.subTitle }}
      </div>
      <a
        class="btn wow fadeInRight"
        data-wow-delay="0.4s"
        target="_blank"
        href="http://120.76.44.28/clothing-20240327/#/login"
        >服装系统试用</a
      >
    </div>
  </div>
</template>
<script>
const bannerDate = [
  {
    title: "产品中心",
    subTitle: "涵盖一系列RFID硬件产品及标签，一站式满足您的RFID设备需求",
    pcBackImg: "tags.png",
    mobieBackImg: "tags-m.png",
  },
  {
    title: "软件平台智慧服装系统",
    subTitle: "系统可按业务需求定制",
    pcBackImg: "software.png",
    mobieBackImg: "software-m.png",
  },
  {
    title: "解决方案",
    subTitle: "一站式鞋服行业RFID解决方案供应",
    pcBackImg: "plan.png",
    mobieBackImg: "plan-m.png",
  },
  {
    title: "新闻中心",
    subTitle: "了解更多IOT行业资讯内容，拓展更多RFID技术解决方案",
    pcBackImg: "news.png",
    mobieBackImg: "news-m.png",
  },
  {
    title: "关于我们",
    subTitle:
      "为用户提供RFID和物联网技术系统解决方案，助力企业信息化建设与管理创新。",
    pcBackImg: "about.png",
    mobieBackImg: "about-m.png",
  },
];
const planImg = [
  {
    name: "贴标管理",
    pcBackImg: "plan1.png",
    mobieBackImg: "plan1.png",
  },
  {
    name: "衣服盘点",
    pcBackImg: "plan2.png",
    mobieBackImg: "plan2.png",
  },
  {
    name: "快速收银",
    pcBackImg: "plan3.png",
    mobieBackImg: "plan3.png",
  },
  {
    name: "快速查找",
    pcBackImg: "plan4.png",
    mobieBackImg: "plan4.png",
  },
  {
    name: "智能试衣间",
    pcBackImg: "plan5.png",
    mobieBackImg: "plan5.png",
  },
  {
    name: "智能货架",
    pcBackImg: "plan6.png",
    mobieBackImg: "plan6.png",
  },
  {
    name: "防盗防损",
    pcBackImg: "plan7.png",
    mobieBackImg: "plan7.png",
  },
];
export default {
  props: {
    title: {
      type: String,
      default: () => {
        return "";
      },
    },
    color: {
      type: String,
      default: () => {
        return "white";
      },
    },
  },
  computed: {
    titles() {
      if (this.title == "解决方案") {
        return this.$route.query.name + this.titleDate.title;
      } else {
        return this.titleDate.title;
      }
    },
  },
  created() {
    this.titleDate = bannerDate.filter((item) => {
      return item.title == this.title;
    })[0];
    if (this.title == "解决方案") {
      let obj = planImg.find((item) => item.name == this.$route.query.name);
      this.titleDate.pcBackImg = obj.pcBackImg;
      this.titleDate.mobieBackImg = obj.mobieBackImg;
    }
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .top {
    position: relative;
    @if ($searchWidth<1100) {
      margin-top: 3.2rem;
      min-height: 18rem;
      overflow: hidden;
    }

    img {
      width: 100%;
      display: block;
      @if ($searchWidth<1100) {
        height: 18rem;
        width: auto;
      }
    }
  }

  .text {
    position: absolute;
    top: 40%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 13.2rem;
    z-index: 100;
    @if ($searchWidth<1100) {
      width: 100%;
      padding-left: 0;
      align-items: center;
    }

    .t1 {
      // font-size: 0.6rem;
      font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;

      @if ($searchWidth<1100) {
        font-size: 1rem;
      } @else {
        font-size: 0.44rem;
      }
    }

    .t2 {
      // font-size: 0.3rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;

      @if ($searchWidth<1100) {
        font-size: 0.7rem;
        margin: 0.7rem 0 1.25rem;
        max-width: 14rem;
        text-align: center;
      } @else {
        font-size: 0.24rem;
        margin: 0.1rem 0 0.3rem;
        width: 4.82rem;
        white-space: pre-wrap;
      }
    }

    .btn {
      // height: 0.64rem;
      // line-height: 0.64rem;
      background: #5ec7b9;
      // border-radius: 0.32rem;
      // font-size: 0.24rem;
      font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
      font-weight: normal;
      color: #ffffff;
      display: inline-block;
      // padding: 0 0.26rem;
      text-decoration: none;

      @if ($searchWidth<1100) {
        height: 2rem;
        line-height: 2rem;
        font-size: 0.7rem;
        border-radius: 8px;
        padding: 0 1.2rem;
      } @else {
        height: 0.5rem;
        line-height: 0.5rem;
        font-size: 0.2rem;
        border-radius: 0.08rem;
        padding: 0 0.38rem;
      }
    }
  }
  .isBlack {
    .t1 {
      color: #333333;
    }
    .t2 {
      color: #333333;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
