<template>
  <div class="container">
    <Title :title="'公司简介'"></Title>
    <div class="main-warp">
      <div class="main wow fadeInUp">
        <div class="text">
          <div class="t1">
            {{ "关于常达" }}
          </div>
          <div class="t2">
            {{
              "常达智能物联(深圳)有限公司为常达控股有限公司全资子公司（香港主板上市，股票代码：1433.HK）。 总部位于香港,于1992年开始印刷业务，主要生产和销售服装标签和装饰产品，" +
              "如吊牌、织唛、印唛和热转印产品。常达控股在美国设有支援生产线，拥有逾400部机器，可生产种类繁多的RFID服装标签及装饰产品。" +
              "依托常达控股在中国、孟加拉国、越南、土耳其、洛杉矶、危地马拉、印度、墨西哥设立的生产基地和全球40多个国家成立的办事处，构建了完整高效的全球供应链体系，常达智能物联可快速作出市场响应。"
            }}
          </div>
        </div>
        <div class="image">
          <img :src="require('@/assets/about/infos.png')" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Title from "@/views/plans/components/Title.vue";
export default {
  components: {
    Title,
  },
};
</script>
<style lang="scss" scoped>
@mixin searchWidth($searchWidth) {
  .container {
    background: #ffffff;
    margin-bottom: 0.94rem;

    @if ($searchWidth<1100) {
    } @else {
    }
  }
  .main-warp {
    background: linear-gradient(179deg, #fafcff 0%, #ffffff 100%);
    box-shadow: -2px 6px 12px 5px rgba(109, 154, 148, 0.18),
      inset 0px 1px 3px 0px rgba(255, 255, 255, 0.5);
    border: 2px solid #ffffff;
    margin: auto;
    @if ($searchWidth<1100) {
      height: auto;
      width: 95%;
    } @else {
      height: 3.9rem;
      max-width: 13.2rem;
    }
  }
  .main {
    margin: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    @if ($searchWidth<1100) {
      padding: 1.2rem 0.8rem;
      flex-direction: column;
    } @else {
      height: 100%;
    }
  }

  .text {
    @if ($searchWidth<1100) {
    } @else {
      width: 58%;
    }
  }

  .t1 {
    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #333333;

    @if ($searchWidth<1100) {
      font-size: 1rem;
      margin: 0 auto 0.8rem;
    } @else {
      line-height: 0.34rem;
      font-size: 0.3rem;
      max-width: 6.94rem;
      margin: 0 auto 0.3rem;
    }
  }

  .t2 {
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;
    font-weight: normal;
    color: #7a7a7a;
    // max-width: 5.17rem;
    margin: auto;

    @if ($searchWidth<1100) {
      line-height: 1.25rem;
      font-size: 0.7rem;
    } @else {
      line-height: 0.34rem;
      font-size: 0.18rem;
      max-width: 6.94rem;
    }
  }
  .image {
    width: 42%;
    display: flex;
    align-items: center;
    @if ($searchWidth<1100) {
      width: 100%;
    }
    img {
      width: 100%;
    }
  }
}

@media (max-width: 1100px) {
  @include searchWidth(999);
}

@media (min-width: 1100px) {
  @include searchWidth(1101);
}
</style>
